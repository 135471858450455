// Colours
$white: #ffffff;
$black: #000000;
$light-grey: #f6f6f6;
$dark-grey: #212121;

$background: #000108;

$primary: #727f95;
$secondary: #276dfe;

$positive: #0ab04b;
$negative: #ff4343;

$label: #1c4c56;
$input-bg: #ffffff;
$input-border: #dfdfdf;
$input-font: #212121;

// Spacing
$btn-padding: 1rem 1.5rem;

// Typography
$font: Arial, Helvetica, sans-serif;

$default-transition: all 0.25s ease;
