@import "~normalize.css/normalize.css";
@import "partials/_variables.scss";
@import "partials/_accordion.scss";
@import "partials/_slider.scss";
@import "partials/_columns.scss";
@import "partials/_buttons.scss";
@import "partials/_forms.scss";
@import "partials/_messages.scss";
@import "partials/_breakpoints.scss";
@import "partials/_cart.scss";

$min-width: 320px;
$max-width: 1440px;

// Global Styles

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body {
    font-family: $font;
    display: table;
    position: relative;
    background: $light-grey;
    width: 100%;
    height: 100%;
    margin: 0;
}

.container {
    min-width: $min-width;
    max-width: $max-width;
    margin: 0 auto;
    padding: 0 40px;

    @include md {
        padding: 0 20px;
    }

    .row {
        display: flex;
        margin: 0 auto;
        justify-content: space-between;
        flex-wrap: nowrap;
    }
}

// Error Pages

.text-error {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    padding: 0.5em;
    transform: translate(-50%, -50%);
    text-align: center;
}

nav {
    &.breadcrumbs {
        text-align: left;

        ul {
            display: inline-block;

            li {
                display: inline-block;

                &:not(:last-child)::after {
                    content: ">";
                    display: inline-block;
                }
            }
        }
    }
}

body > header {
    nav {
        ul {
            li {
                list-style: none;
                display: inline-block;
            }
        }
    }
}

main {
    &.account,
    &.checkout {
        ul.sections {
            justify-content: center;
            li {
                display: block;
                padding: 0 10px;
                a {
                    display: block;
                    border: 1px solid $black;
                    border-radius: 5px;
                    padding: 10px 20px;
                }
                &.current {
                    a {
                        background: $black;
                        color: $white;
                    }
                }
            }
        }
        form {
            max-width: 600px;
            flex: 1 0 auto;
        }
        &.address,
        &.cart {
            form {
                max-width: 980px;
                flex: 1 0 auto;
            }
        }
    }
    &.checkout {
        &.cart {
            .cart-items {
                & > li.cart-item {
                    padding: 5px 10px;
                    input {
                        margin: 0;
                    }
                    .item-image {
                        width: 40px;
                        height: 40px;
                        flex: 0 0 auto;
                    }
                    .item-details {
                        text-align: left;
                        justify-self: flex-start !important;
                        flex: 1 0 auto;
                        padding: 0 10px;
                        width: calc((100% - 200px) / 2);
                    }
                    .item-qty {
                        flex: 0 0 auto;
                        padding: 0 10px;
                        width: calc((100% - 200px) / 2);
                        input {
                            display: inline-block;
                            width: 8rem;
                        }
                    }
                    .item-price {
                        flex: 0 0 auto;
                        text-align: right;
                        max-width: 8rem;
                        padding: 0 10px;
                    }
                    .item-remove {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
            .totals {
                font-weight: 600;
                padding: 0 20px;
                span {
                    display: inline-block;
                    min-width: 8rem;
                }
            }
        }
        &.shipping,
        &.payment {
            & > .container > .row {
                justify-content: center;
                align-items: flex-start;
            }
            form {
                margin: 0;
                fieldset {
                    &.shipping-methods {
                        padding: 20px;
                        label {
                            padding: 10px;
                            display: flex;
                            align-items: center;
                            input {
                                margin-right: 1em;
                            }
                            span {
                                font-weight: 600;
                                display: inline-block;
                                margin-left: 1em;
                            }
                        }
                    }
                    &.buttons {
                        display: flex;
                        justify-content: center;
                        button,
                        .button {
                            margin: 2em 10px 0;
                        }
                    }
                }
                .payment-method {
                    margin-top: 20px;
                    padding-top: 20px;
                }
                .message {
                    padding: 20px 10px;
                    text-align: left;
                    ul {
                        margin: 1em 2em;
                    }
                }
            }
            .totals {
                border-radius: 5px;
                padding: 40px 40px;
                max-width: 330px;
                margin: 0 10px;
                flex: 1 0 auto;
                text-align: left;
                ul {
                    margin: 1em 0;
                    li {
                        list-style: none;
                        display: flex;
                        justify-content: space-between;
                        font-weight: 600;
                        padding: 0.5em 5px;
                        &.total {
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
}

main {
    &.products {
        ul.row {
            justify-content: flex-start;
            li.product {
                list-style: none;
                border: 1px solid $black;
                overflow: hidden;
                width: calc(25% - 20px);
                margin: 10px;
                a {
                    &.image {
                        display: block;
                        width: 100%;
                        padding-top: 100%;
                        background-size: contain;
                    }
                }
                div {
                    text-align: left;
                    padding: 20px 40px;
                }
            }
        }
        &.single {
        }
    }
}

// Flatpickr
.flatpickr-wrapper {
    @apply w-full;
}

.flatpickr-calendar.open {
    z-index: 1000 !important;
    filter: none !important;
}

.flatpickr-months {
    @apply mb-2 text-gray-800;
}

.flatpickr-next-month,
.flatpickr-prev-month {
    @apply text-gray-800;

    svg {
        fill: currentColor !important;
    }
}

.flatpickr-current-month span.cur-month:hover,
.numInputWrapper:hover {
    @apply bg-transparent;
}

.flatpickr-day {
    @apply border-0;

    &.today {
        @apply font-semibold;

        &:hover {
            color: inherit;
            @apply bg-gray-400;
        }
    }

    &:hover {
        @apply bg-gray-400;
    }

    &.selected {
        @apply bg-blue-600 text-white;

        &:hover,
        &.today:hover {
            @apply bg-blue-400 text-white;
        }
    }
}

.flatpickr-time {
    border: none !important;

    input,
    .flatpickr-am-pm,
    .flatpickr-time {
        background: none !important;
    }
}

.flatpickr-time input.flatpickr-hour,
.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
    @apply font-semibold;

    &::selection {
        background: none;
    }
}

// HSW

// Global

// -- Pagination

.pagination {
    margin: 1em 0;
    display: flex;
    width: 100%;
    justify-content: center;
    list-style: none;
    padding-left: 0;

    @include md {
        margin: 2rem auto;
        justify-content: center;
    }

    .page-item {
        height: 100%;
        padding: 5px;
        text-align: center;
        margin: 0;
        line-height: normal;

        @include xs {
            padding: 2px;
        }

        @include xxs {
            padding: 0;
        }

        a {
            display: block;
            height: 100%;
            color: $background;
            font-weight: 600;
            text-decoration: none;
            cursor: pointer;
        }
        &:hover {
            color: $secondary;
            text-decoration: none;
        }
        &:first-of-type {
            border-left: 0;
        }
        &.active {
            span {
                color: $secondary;
                font-weight: 600;
            }
        }
        &.disabled {
            background: transparent;
            color: $background;
        }
        &.arrow {
            background: none;
            a {
                color: $primary;
            }
        }
    }
}

span.section-title {
    display: block;
    font-size: 1.5rem;
    margin-bottom: 1em;

    @include xs {
        font-size: 1.25rem;
    }
}

h1.section-title {
    display: block;
    margin-top: 0;
    margin-bottom: 1em;

    @include xs {
        font-size: 1.5rem;
    }
}

header {
    position: relative;
    background: $white;

    .container {
        padding-top: 1em;
        padding-bottom: 1em;

        @include xs {
            padding-top: 0.5em;
            padding-bottom: 0.5em;
        }

        .row {
            justify-content: space-between;
            align-items: center;
        }
    }

    .column.left {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .column.right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 60%;

        @include lg {
            width: 45%;
        }

        @include xs {
            width: 100%;
        }
    }

    .logo {
        display: inline-flex;
        background-image: url("/img/amotiv-logo.svg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: 200px;
        height: 50px;
        text-indent: -999em;
        overflow: hidden;
        z-index: 1;

        @include md {
            width: 140px;
            height: 50px;
        }

        @include xs {
            width: 110px;
            height: 50px;
        }
    }

    nav {
        &.user {
            display: flex;
            flex-direction: row;
        }

        &.menu-nav {
            display: flex;
            justify-content: flex-start;
            flex-direction: row;
            margin-left: 1em;

            @include lg {
                margin-left: 0.5em;
            }

            ul li {
                margin: 0 1em;

                @include lg {
                    margin: 0 0.5em;
                }
            }
        }

        ul {
            display: flex;
            flex-direction: row;

            li {
                &.account {
                    cursor: pointer;
                    padding: 0.75em;
                    margin-right: 0.5em;
                    border-radius: 100%;
                    background-color: $light-grey;
                    .icon {
                        mask: url("/img/icon-user-dark.svg") center center /
                            1.125rem no-repeat;
                    }

                    &:hover {
                        background-color: $secondary;

                        .icon {
                            background: $white;
                        }
                    }
                }

                &.logout {
                    cursor: pointer;
                    padding: 0.75em;
                    border-radius: 100%;
                    background-color: $light-grey;
                    .icon {
                        mask: url("/img/icon-logout-dark.svg") center center /
                            1.125rem no-repeat;
                    }

                    &:hover {
                        background-color: $negative;

                        .icon {
                            background: $white;
                        }
                    }
                }

                a {
                    display: flex;
                    color: $primary;
                    text-decoration: none;

                    &:hover {
                        color: $secondary;
                    }
                }

                .icon {
                    display: block;
                    background: $primary;
                    width: 18px;
                    height: 18px;
                    text-indent: -999rem;
                }
            }
        }
    }

    .search-form {
        position: relative;
        width: 100%;
        max-width: 25em;
        margin-right: 1.5em;

        @include lg {
            margin-right: 0.5em;
        }

        label {
            display: none;
        }

        input {
            width: 100%;
            background: none;
            margin: 0;
            padding: 0.75rem 1.25rem;
            font-size: 1rem;
            color: $primary;
            border: none;
            border-radius: 30px;
            background: $light-grey;

            &::placeholder {
                color: $primary;
            }

            @include xs {
                font-size: 90%;
                padding: 0.5rem 1rem;
            }
        }

        button {
            margin: 0;
            color: $primary;
            position: absolute;
            top: 50%;
            right: 0;
            background: transparent;
            width: 3.5rem;
            height: 3.5rem;
            border: none;
            transform: translate(0, -50%);

            &::before {
                content: none;
            }
        }

        .icon {
            display: block;
            background: $primary;
            mask: url("/img/icon-search.svg") center center / 1.125rem no-repeat;
            transition: all 0.25s ease;
            text-indent: -999rem;
            width: 3.5rem;
            height: 3.5rem;
            margin: -1.5em;
        }

        &:hover {
            border-color: $secondary;

            .icon {
                background: $secondary;
            }
        }
    }
}

header.desktop {
    display: block !important;

    @include xs {
        display: none !important;
    }
}

header.mobile {
    display: none !important;

    @include xs {
        display: block !important;
    }

    .burger-menu {
        display: none;

        @include xs {
            position: relative;
            display: block;
            width: 1.75rem;
            height: 1.5rem;
            top: 0;
            border-bottom: 0 none;
            cursor: pointer;
            z-index: 101;
            transition: opacity 0.25s ease;
        }

        &:hover {
            opacity: 0.7;
        }

        &.active {
            .top {
                transform: translateY(11px) translateX(0) rotate(45deg);
                background: $primary;
            }

            .middle {
                opacity: 0;
                background: $primary;
            }

            .bottom {
                transform: translateY(-8px) translateX(0) rotate(-45deg);
                background: $primary;
            }
        }

        span {
            background: $primary;
            border: none;
            height: 2px;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            transition: all 0.35s ease;
            cursor: pointer;
            z-index: 101;

            &:nth-of-type(2) {
                top: 9px;
            }

            &:nth-of-type(3) {
                top: 19px;
            }
        }
    }

    .overlay {
        position: fixed;
        background: $white;
        top: 0;
        left: 0;
        width: 100%;
        height: 0%;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.35s, visibility 0.35s, height 0.35s;
        // overflow: hidden;
        z-index: 100;

        &.open {
            opacity: 1;
            visibility: visible;
            height: 100%;

            li {
                animation: fadeInRight 0.5s ease forwards;
                animation-delay: 0.35s;

                &:nth-of-type(2) {
                    animation-delay: 0.4s;
                }

                &:nth-of-type(3) {
                    animation-delay: 0.45s;
                }

                &:nth-of-type(4) {
                    animation-delay: 0.5s;
                }

                &:nth-of-type(5) {
                    animation-delay: 0.55s;
                }

                &:nth-of-type(6) {
                    animation-delay: 0.6s;
                }

                &:nth-of-type(7) {
                    animation-delay: 0.65s;
                }

                &:nth-of-type(8) {
                    animation-delay: 0.7s;
                }

                &:nth-of-type(9) {
                    animation-delay: 0.75s;
                }

                &:nth-of-type(10) {
                    animation-delay: 0.8s;
                }

                &:nth-of-type(11) {
                    animation-delay: 0.85s;
                }

                &:nth-of-type(12) {
                    animation-delay: 0.9s;
                }
            }
        }

        nav.overlay-menu {
            position: relative;
            top: 4em;
            left: 50%;
            transform: translate(-50%, 4em);
        }

        nav.menu-nav {
            flex-direction: column;
            margin-left: 0;
            justify-content: center;

            ul {
                flex-direction: column;
                text-align: center;

                li {
                    margin: 1em auto;

                    a {
                        font-size: 1.125rem;
                    }
                }
            }
        }

        nav.user {
            margin: 1em auto;
            justify-content: center;
        }
    }

    .desktop {
        @extend .container;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 40px;

        @include xs {
            padding: 1em 20px;
        }

        .email-button {
            width: fit-content;
            display: flex;
            align-items: center;

            text-transform: uppercase;
            text-decoration: none;
            cursor: pointer;

            background-color: $primary;
            color: $black;
            font-weight: 600;
            padding: 5px 18px 5px 16px;
            border-radius: 30px;
            height: 35px;
            font-size: 1rem;
            white-space: nowrap;
            vertical-align: middle;
            z-index: 1;

            @include md {
                display: none;
            }

            &::before {
                content: url("~/img/email.svg");
                width: 20px;
                height: 20px;
                float: left;
                margin-right: 5px;
            }
        }
    }
}

// -- Footer

footer {
    display: table-row;
    height: 0;
    width: 100%;
    background: #0c2036;

    .container {
        padding: 1em 40px;
    }

    .row {
        display: flex;
        justify-content: space-between;
    }

    span {
        color: $white;
        font-size: 0.75rem;
    }

    a {
        color: $white;
        font-size: 0.75rem;
    }
}

// -- Background wtapper

.background-wrapper {
    height: 100%;
    padding: 2em;
    border-radius: 14px;
    background: $white;

    &.index {
        height: 100vh;
        padding: 1em 3em;

        @include md {
            height: 100%;
            padding: 1em 2em;
        }

        @include xs {
            padding: 1em 1.5em;
        }
    }

    @include md {
        height: 100%;
    }

    @include xs {
        padding: 1em;
    }
}

// -- Main Login

main.main-login-page {
    position: relative;
    width: 100%;
    height: 100vh;
    background-image: url("/img/bg-blue.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

#home-login-page {
    height: 100%;

    .row {
        height: 100%;
        align-items: center;
        justify-content: center;
    }

    .column.right {
        position: relative;
        display: block;
        width: 500px;
        padding: 2em;
        border-radius: 8px;
        background: $white;

        @include xs {
            width: 100%;
            padding: 1.5em;
        }
    }

    .forgot-pwd {
        display: block;
        margin: 0.5em auto;
        color: $black;
        text-align: center;
    }
}

// -- Sidebar

.sidebar.desktop {
    display: block;
    width: 25%;
    height: fit-content;

    @include md {
        display: none;
    }

    #sidebar {
        border-radius: 8px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        margin-right: 2em;

        @include lg {
            margin-right: 0;
        }
    }
}

#sidebar {
    padding: 1.5em;

    .sidebar-prompt {
        display: block;
        font-size: 1.5rem;
        font-weight: 600;
    }

    ul.category-grid {
        display: block;
        margin: 1em 0;

        .category-item {
            display: block;
            padding: 1em 1.25em;
            border-radius: 8px;
            list-style-type: none;
            cursor: pointer;

            &.active {
                background: #e9f0ff;

                a {
                    color: $secondary;
                }
            }

            &:hover {
                a {
                    color: $secondary;
                }
            }

            a {
                color: $background;
                text-decoration: none;
            }
        }
    }
}

.product-filter-popup-sort {
    display: none !important;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;
    width: 100%;

    @include md {
        display: flex !important;
        align-items: flex-start;
        margin-bottom: 0;
    }

    .filter-btn {
        width: 100%;
        border-radius: 4px;
        color: $white;
        text-align: center;
        background: $background;
        margin-top: 0;
    }
}

// Modal pop up
.popup {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba($black, 0.75);
    width: 100%;
    height: 100%;
    transition: $default-transition, opacity 0.25s ease 0s,
        visibility 0s ease 0.25s;
    visibility: hidden;
    opacity: 0;

    &.is-visible {
        transition: $default-transition, opacity 0.25s ease 0s,
            visibility 0s ease 0s;
        visibility: visible;
        opacity: 1;
        z-index: 999;
    }
}

.popup-container {
    position: relative;
    padding-top: 3rem;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    background: $white;
    text-align: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    transform: translateY(-40px);
    transition-property: transform;
    transition-duration: 0.3s;
    overflow-y: scroll;

    #sidebar {
        display: block !important;
        margin-right: 0;
        padding: 0 2em;

        .categories li {
            padding: 0.5rem;
            border-bottom: 1px solid $primary;

            ul li.sidebar__subcategory {
                border: none;
                padding-left: 1rem;
            }

            .sidebar__toggle {
                font-size: 1.25em;
            }

            @include xs {
                border: none;
                padding: 0;
            }
        }
    }
}

.popup-container .cd-buttons::after {
    content: "";
    display: table;
    clear: both;
}

.popup-container .cd-buttons a {
    display: block;
    height: 60px;
    line-height: 60px;
    text-transform: uppercase;
    color: #fff;
    transition: background-color 0.2s;
}

.popup-container .cd-buttons li:first-child a {
    background: #8dc642;
    border-radius: 0 0 0 0.25em;
}

.no-touch .popup-container .cd-buttons li:first-child a:hover {
    background-color: #8dc642;
}

.popup-container .cd-buttons li:last-child a {
    background: #b6bece;
    border-radius: 0 0 0.25em 0;
}

.no-touch .popup-container .cd-buttons li:last-child a:hover {
    background-color: #c5ccd8;
}

.popup-container .popup-close {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 30px;
    height: 30px;
}

.popup-container .popup-close::before,
.popup-container .popup-close::after {
    content: "";
    position: absolute;
    top: 12px;
    width: 22px;
    height: 3px;
    background-color: $primary;
}

.popup-container .popup-close::before {
    transform: rotate(45deg);
    left: 6px;
}

.popup-container .popup-close::after {
    transform: rotate(-45deg);
    right: 3px;
}

.is-visible .popup-container {
    transform: translateY(0);
}

@media only screen and (min-width: 1170px) {
    .popup-container {
        margin: 8em auto;
    }
}

// -- Dashboard

.bento-menu {
    display: flex;
    justify-content: space-between;
    height: 45em;
    margin: 1em 0;

    @include md {
        flex-direction: column;
        height: 100%;
    }

    .bento-col-left {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        text-decoration: none;
        width: 50%;
        height: 100%;
        padding: 2em;
        margin-right: 2em;
        border-radius: 10px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

        @include md {
            height: 24em;
            width: 100%;
            margin-bottom: 2em;
            margin-right: 0;
        }

        &:hover {
            transition: 0.2s ease-in-out;
            box-shadow: 2px 2px 2px rgb(114 127 149);

            .bento-text-content {
                .links-arrow {
                    transform: rotate(38deg);
                    transition: 0.1s ease-in;
                    background: $secondary;
                }
            }
        }

        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border-radius: 10px;
            background: rgba($black, 0.5);
            background: linear-gradient(rgba($black, 0.5), $black);
            opacity: 0.75;
            z-index: 1;
        }

        .bento-text-content {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            text-decoration: none;
            z-index: 2;

            h3.title {
                color: $white;
                font-size: 1.75rem;

                @include xs {
                    font-size: 1.5rem;
                }
            }

            .links-arrow {
                display: inline-block;
                width: 3.25em;
                height: fit-content;
                vertical-align: middle;
                transition: 0.1s ease-in;
                margin-left: 0.5em;
                padding: 0.5em;
                border-radius: 100%;
                background: transparent;
            }
        }
    }

    .bento-col-right {
        position: relative;
        width: 50%;
        height: calc(100% - 34px);

        @include md {
            width: 100%;
        }

        .prompt-text {
            display: block;
            font-size: 1rem;
            font-weight: 600;
            letter-spacing: 1.1px;
            text-transform: uppercase;
            margin-bottom: 1em;
        }
    }
}

.bento-grid-4 {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2em;

    &.links-index {
        @include xs {
            margin: 2em 0;
        }

        .bento-grid-item {
            height: 20em;
            padding: 1.5em;

            .bento-text-content {
                margin: 0;
                justify-content: space-between;

                .text {
                    margin: 0;
                    width: 80%;

                    h3.title {
                        font-size: 1.5rem;
                        text-transform: uppercase;

                        @include xs {
                            font-size: 1.25rem;
                        }
                    }

                    p {
                        font-size: 0.875rem;
                        display: block;
                        margin-top: 0.5em;
                        color: $background;
                    }
                }

                .links-arrow {
                    max-width: 48px;
                }
            }
        }
    }

    @include lg {
        gap: 1.5em;
    }

    @include xs {
        grid-template-columns: repeat(1, 1fr);
    }

    .bento-grid-item {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 1.75em;
        border-radius: 10px;
        background-color: $white;
        text-decoration: none;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

        @include lg {
            height: auto;
            padding: 1.5em;
        }

        @include md {
            height: 17em;
        }

        &:hover {
            transition: 0.2s ease-in-out;
            box-shadow: 2px 2px 2px rgb(114 127 149);

            .bento-text-content {
                .links-arrow {
                    transform: rotate(38deg);
                    transition: 0.1s ease-in;
                    background: $secondary;
                }
            }
        }

        &.corp {
            background-image: url("/img/amotiv-logo.svg");
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }

        &.cat1 {
            background-image: url("/img/cat-1.png");
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }

        &.cat2 {
            background-image: url("/img/cat-2.png");
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }

        &.cat3 {
            background-image: url("/img/cat-3.png");
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }

        .bento-text-content {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            z-index: 2;

            @include xs {
                justify-content: space-between;
            }

            h3.title {
                width: 80%;
                padding-right: 0.5em;
                color: $background;
                font-size: 1.125rem;

                @include xs {
                    width: 85%;
                }
            }

            .links-arrow {
                display: inline-block;
                width: 15%;
                height: fit-content;
                vertical-align: middle;
                transition: 0.1s ease-in;
                margin-left: 0.5em;
                padding: 0.5em;
                border-radius: 100%;
                background: $primary;

                @include md {
                    width: 12%;
                }
            }
        }
    }
}

// -- External Links Page

#external-links-page {
    margin: 2em auto 4em;

    .row {
        @include md {
            flex-direction: column;
        }
    }
}

.text {
    margin: 1em 0;

    h2.section-title {
        width: 100%;
        padding: 0.5em 1em;
        color: $white;
        background: $background;
        margin-bottom: 1em;
    }
}

#external-links {
    p.division-title {
        display: block;
        font-size: 1.25rem;
        font-weight: 600;
        padding-bottom: 1em;
        margin-left: 1em;
        border-bottom: 1px solid $background;
    }

    img {
        max-width: 300px;
        padding: 1em;
        height: 200px;
        object-fit: contain;
        border: 1px solid $light-grey;

        @include md {
            width: 100% !important;
        }

        &:hover {
            border: 1px solid #0000ff;
        }
    }

    .fr-img-caption {
        display: block;
        text-align: center;
        margin-left: 1em;
        margin-bottom: 1em;
    }

    .fr-inner {
        font-size: 0.875rem;
        color: $black;
        text-transform: uppercase;
        text-decoration: none;

        &:hover {
            color: #0000ff;
        }
    }

    .fr-img-wrap {
        cursor: pointer;

        a {
            color: $black;
            text-transform: uppercase;
            text-decoration: none;

            &:hover {
                color: #0000ff;
            }
        }
    }
}

// -- Documents Index page

#category-tags {
    margin: 2em auto 4em;

    @include xs {
        margin: 2em auto;
    }

    &:last-of-type {
        margin: 2em auto 0;

        @include xs {
            margin-bottom: 2em;
        }
    }

    span {
        display: block;
        font-size: 1.5rem;
        margin-bottom: 1em;
        @include xs {
            font-size: 1.25rem;
        }
    }
    ul.category-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        list-style-type: none;
        gap: 2em;

        @include lg {
            grid-template-columns: repeat(3, 1fr);
            gap: 1.5em;
        }

        @include md {
            grid-template-columns: repeat(2, 1fr);
            gap: 1.5em;
        }

        @include xs {
            grid-template-columns: repeat(1, 1fr);
            gap: 1em;
        }

        .category-item {
            position: relative;
            border-radius: 8px;
            background: $light-grey;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

            @include xs {
                margin: 0.25em 0;
            }

            &:hover {
                cursor: pointer;
                background: $secondary;

                span {
                    color: $white;
                }
                img.icon {
                    filter: brightness(0) invert(1);
                }
            }

            a {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                text-decoration: none;
                height: 100%;
                padding: 1.25em;

                @include xs {
                    padding: 1em;
                }
            }

            span {
                margin: 0;
                font-size: 1rem;
                color: $black;

                @include xs {
                    font-size: 0.875rem;
                }
            }

            img.icon {
                display: block;
                width: 1.125rem;
                height: 1.125rem;
                object-fit: contain;
                margin-right: 1em;
            }
        }
    }
}

// -- Documents page

#documents-result-page {
    margin: 2em auto 2em;
    .category-links-wrapper{
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        justify-content: center;
        padding-bottom: 2rem;
        .category-link{
            background-color: rgba(255, 255, 255, 0.121);
            color: $primary;
            padding: 0.3rem 2rem;
            margin: 0.5rem;
            border: solid 1px $primary;
            border-radius: 5px;
            box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 2px 0px;
            transition: all 0.1s ease-in;
            font-size: .8rem;
            text-decoration: none;
            text-transform: uppercase;
            font-weight: 600;

            &:hover {
                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 2px 0px;
                scale: 1.02;
            }
        }
    }



    .row {
        @include md {
            flex-direction: column;
        }
    }

    .show-prompt {
        display: block;
        color: $primary;
        font-size: 1rem;
        margin-bottom: 1em;

        @include md {
            font-size: 0.95rem;
        }
    }

    #results-grid {
        width: calc(100% - 25% - 2em);

        @include md {
            width: 100%;
        }

        .top-heading-row {
            display: grid;
            grid-template-columns: 5em 80%;
            gap: 1em;
            padding: 1em 1.5em;
            border-radius: 8px 8px 0 0;
            background: $primary;

            @include xs {
                grid-template-columns: 5em 75%;
                padding: 1.25em 1em;
            }

            .top-heading-col {
                &.end {
                    display: flex;
                    justify-content: flex-end;
                }

                p {
                    color: $white;
                }
            }
        }

        .results-list {
            border-left: 2px solid $light-grey;
            border-right: 2px solid $light-grey;

            .results-row {
                display: grid;
                grid-template-columns: 5em 80%;
                gap: 1em;
                padding: 1.5em 1em;
                border-bottom: 2px solid $light-grey;

                @include xs {
                    grid-template-columns: 5em 75%;
                    padding: 1.25em 1em;
                }

                &:hover {
                    background: $light-grey;
                }
            }

            .file-name {
                display: block;
                padding: 0 1em;

                @include xs {
                    padding: 0 1em 0 0;
                }

                a {
                    display: block;
                    width: 100%;
                    font-weight: 600;
                    margin-bottom: 0.5em;
                    color: $background;
                    white-space: break-spaces;

                    &:hover {
                        color: $secondary;
                    }
                }

                .file-category {
                    display: block;
                    text-transform: uppercase;
                }
            }

            .icon-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;

                .icon.default {
                    display: block;
                    width: 100%;
                    height: 2em;
                    margin: 0 auto;
                    object-fit: contain;
                }
            }

            .file-download {
                a {
                    display: flex;
                    justify-content: flex-end;
                }

                display: block;
                width: 100%;
                height: 3em;
                background: $background;
                mask: url("/img/icon-download.svg") center center / 1.85rem
                    no-repeat;
                transition: all 0.25s ease;

                &:hover {
                    cursor: pointer;
                    background: #0000ff;
                }
            }
        }
    }
}

// -- External Links page

// Index
#links-index-page {
    margin: 2em auto;

    .links-category-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        list-style-type: none;
        gap: 2em;

        &.child {
            grid-template-columns: repeat(4, 1fr);

            @include md {
                grid-template-columns: repeat(2, 1fr);
                gap: 1.5em;
            }

            @include xs {
                grid-template-columns: repeat(1, 1fr);
                gap: 1.5em;
            }
        }

        @include md {
            grid-template-columns: repeat(1, 1fr);
            gap: 1.5em;
        }

        .links-category {
            position: relative;
            width: 100%;
            height: 15.625em;
            border-radius: 8px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            text-decoration: none;
            background-color: $white;
            cursor: pointer;

            &.child {
                height: 12.625em;
                background-size: contain;
                background-position: 50% 1em;

                @include xs {
                    height: 10.625em;
                }

                .links-content {
                    padding: 0 1em 1em 1em;

                    .links-text {
                        h4 {
                            font-size: 1rem;
                        }
                    }

                    .links-arrow {
                        max-width: 2em;
                    }
                }
            }

            @include md {
                background-size: contain;
            }

            @include xxs {
                height: 17em;
            }

            &:hover {
                transition: 0.2s ease-in-out;
                box-shadow: 2px 2px 2px rgb(114 127 149);

                .links-content {
                    .links-arrow {
                        transform: rotate(38deg);
                        transition: 0.1s ease-in;
                        background: $secondary;
                    }
                }
            }

            .links-content {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 2em 2em 2em;
                z-index: 2;

                @include xs {
                    padding: 0 1.5em 2em 1.5em;
                }

                @include xxs {
                    padding: 0 1em 2em 1em;
                }

                .links-text {
                    max-width: calc(100% - 70px);
                }

                h4 {
                    font-size: 1.5rem;
                    text-transform: uppercase;
                    color: $background;

                    @include xs {
                        font-size: 1.25rem;
                    }
                }

                p {
                    font-size: 0.875rem;
                    display: block;
                    margin-top: 0.5em;
                    color: $background;
                }
            }

            .links-arrow {
                display: block;
                width: 3em;
                transition: 0.1s ease-in;
                padding: 0.5em;
                border-radius: 100%;
                background: $primary;
            }
        }
    }
}

// -- Tilegroup for Links

#tilegroup-cms {
    margin: 2em auto 4em;
}

.tilegroup-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2em;

    @include xs {
        grid-template-columns: repeat(2, 1fr);
        gap: 1.5em;
    }

    .tilegroup-item {
        padding: 2em;
        text-decoration: none;
        background: $white;
        border-radius: 8px;
        cursor: pointer;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

        &:hover {
            transition: 0.2s ease-in-out;
            box-shadow: 2px 2px 2px rgba(0, 0, 255, 0.95);
        }

        .tilegroup-img {
            display: block;
            width: 100%;
            height: 80px;
            object-fit: contain;
        }
    }
}

// Main

main {
    margin-top: 2rem;
    margin-bottom: 2rem;

    h1 {
        margin-top: 0;
        margin-bottom: 1em;
        font-size: clamp(1.5rem, 0.971rem + 2.353vw, 2rem);
    }
}

// Dashboard

.bento-grid {
    display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;
    margin-bottom: 3rem;

    .bento-item {
        display: flex;
        position: relative;
        flex-direction: column;
        background: $white no-repeat center center / contain;
        width: 100%;
        padding: 1.5rem;
        height: 220px;
        box-shadow: 0px 2px 8px 0px rgba(99, 99, 99, 0.2);
        transition: all 0.25s ease;
        justify-content: flex-end;
        border-radius: 0.625rem;
        text-decoration: none;
        // aspect-ratio: 20 / 17;
        flex-wrap: wrap;
        color: $background;

        &:hover {
            box-shadow: 2px 8px 16px 3px rgba(99, 99, 99, 0.2);

            .bento-item-title {
                &::after {
                    background-color: $secondary;
                    transform: rotate(38deg);
                }
            }
        }

        .bento-item-title {
            position: relative;
            width: 100%;
            font-size: 1.125rem;
            padding-right: 3rem;

            &::after {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                right: 0;
                background: $primary url("/img/arrow-right.svg") no-repeat
                    center center / 1rem;
                width: 2rem;
                height: 2rem;
                transition: all 0.25s ease;
                translate: 0 -50%;
                border-radius: 2rem;
            }
        }

        &.featured {
            background-size: cover;
            aspect-ratio: auto;
            overflow: hidden;
            color: $white;

            &::before {
                content: "";
                position: absolute;
                inset: 0;
                background: linear-gradient(
                    rgba($black, 0) 60%,
                    rgba($black, 0.2),
                    rgba($black, 0.6),
                    rgba($black, 1)
                );
                opacity: 0.75;
            }

            &:hover {
                .bento-item-title {
                    &::after {
                        background-color: $secondary;
                    }
                }
            }

            .bento-item-title {
                // font-size: 1.5rem;

                &::after {
                    background-color: transparent;
                    background-size: 1.5rem;
                    width: 2.5rem;
                    height: 2.5rem;
                }
            }
            .bento-item-subheader {
                position: relative;
                width: 100%;
                color: $white;
                font-size: 0.8rem;
                letter-spacing: 1.2px;
                text-transform: uppercase;
                padding-bottom: 0.7rem;
            }
        }
    }

    .bento-item-white {
        display: flex;
        flex-direction: column;
        padding: unset;
        overflow: hidden;
        box-sizing: border-box;

        .white-img-wrapper {
            height: 160px;
            width: 100%;
            background: $white no-repeat top center / contain;
        }
        .white-img-title-wrapper {
            display: flex;
            align-items: flex-end;
            height: 60px;
            padding: 0 1.5rem 1.5rem 1.5rem;
        }
    }
}

.prompt-text {
    margin-bottom: 1rem;
    text-transform: uppercase;
    letter-spacing: 1.1px;
    font-weight: 600;
    font-size: 1rem;
}

@media only screen and (min-width: 600px) {
    .bento-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (min-width: 1045px) {
    .bento-grid {
        grid-template-columns: repeat(3, 1fr);

        .tile-1 {
            order: 1;
        }
        .tile-2 {
            order: 2;
        }
        .tile-3 {
            order: 4;
        }
        .tile-4 {
            order: 5;
        }
        .tile-5 {
            order: 3;
        }
        .tile-6 {
            order: 6;
        }
    }
    .white-img-title-wrapper {
        height: 40px;
        padding: 1.5rem;
    }
}

// headers news and event pages
#event-page,
#single-event,
#news-page,
#single-news {
    margin: 2em auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .header-block {
        .back-to-wrapper {
            margin-bottom: 2rem;
        }

        .back-to-link {
            color: $primary;
            &:hover {
                text-decoration: none;
            }
        }
        .page-title{
                text-decoration: none;
                color: $black;
            }

    }
    .subhead-uppercase {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1rem;
        margin: 1rem 0;
    }

    .subhead-uppercase-sm {
        text-transform: uppercase;
        color: $black;
        font-size: 0.8rem;
        font-weight: bold;
        margin: 1rem 0;
    }


}

// tile and font styles news and event

.event-item,
.news-item {
    width: 100%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background: $white;
    border-radius: 8px;
    position: relative;
    &::before {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        right: 1rem;
        background: $primary url("/img/arrow-right.svg") no-repeat center center /
            1rem;
        width: 2rem;
        height: 2rem;
        transition: all 0.25s ease;
        translate: 0 -50%;
        border-radius: 2rem;
    }

    &:hover {
        box-shadow: 2px 8px 16px 3px rgba(99, 99, 99, 0.2);
        &::before {
            background-color: $secondary;
            background-color: $secondary;
            transform: rotate(38deg);
        }
    }
    .tiles-details {
        margin-top: 1rem;
        padding-bottom: 3rem;
        width: 100%;
    }

    .event-text-content,
    .news-text-content {
        padding: 1rem;
        text-decoration: none;
        color: $black;
    }

    .tiles-title::after {
        content: "";
        display: block;
        border-top: 1px solid rgb(229, 229, 229);
        margin: 10px 0;
    }
    .tiles-date {
        text-transform: uppercase;
        font-weight: bold;
    }

    .tiles-details {
        margin-top: 1rem;
    }

    .tiles-img-wrapper {
        width: 100%;
        height: 50px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px 10px 0 0;
        }
    }
}

//show news and show event

#single-event,
#single-news {
    .show-block {
        display: flex;
        width: 100%;
        margin-top: 2rem;
        padding: 0;
        flex-direction: column;
        align-items: center;

        .show-content-wrapper {
            background-color: $white;
            width: 70%;
            border-radius: 10px;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

            .show-text-wrapper {
                position: relative;

                .event-category {
                    position: absolute;
                    background-color: $primary;
                    width: 180px;
                    border-radius: 3px 0 0 3px;
                    top: 1rem;
                    right: 0;
                    padding: 0.5rem;
                    display: flex;
                    justify-content: center;
                    align-items: cemter;
                    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 4px 0px;
                    .subhead-white {
                        color: $white;
                        margin: 0;
                    }
                }
                .show-title {
                    margin-bottom: 1rem;
                    width: 70%;
                    max-width: 35rem;
                    @include md {
                        width: 80%;
                    }
                    @include xs {
                        width: 100%;
                    }
                }

                .show-date {
                    &::after {
                        content: "";
                        display: block;
                        border-top: 1px solid rgb(229, 229, 229);
                        margin: 2rem 0;
                    }
                }
            }

            .show-img-wrapper {
                width: 100%;
                height: 300px;
                overflow: hidden;
                border-radius: 10px 10px 0 0;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        @include md {
            .show-content-wrapper {
                width: 90%;
            }
        }

        @include xs {
            .show-content-wrapper {
                width: 100%;
            }
        }
    }
}

// News page

#news {
    #news-page {
        margin: 2em auto;
        box-sizing: border-box;
        .post-tagged {
            font-size: 0.9rem;
            color: $primary;
        }
        .category-links-wrapper {
            display: flex;
            flex-flow: row wrap;
            width: 100%;
            justify-content: center;
            padding-bottom: 2rem;
            .category-link {
                text-decoration: none;
                color: $primary;
                background-color: rgba(255, 255, 255, 0.121);
                padding: 0.3rem 2rem;
                margin: 0.5rem;
                border: solid 1px $primary;
                border-radius: 5px;
                box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 2px 0px;
                transition: all 0.1s ease-in;
                &:hover {
                    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 2px 0px;
                    scale: 1.02;
                }
            }
        }

        .news-grid {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 1em;
            margin-bottom: 2em;

            @include lg {
                grid-template-columns: repeat(3, 1fr);
            }

            @include md {
                grid-template-columns: repeat(2, 1fr);
            }

            @include xs {
                grid-template-columns: repeat(1, 1fr);
            }
            a {
                text-decoration: none;
            }
        }
    }
}

// Events page

#events {
    #event-page {
        margin: 2em auto;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        .events-grid {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 1em;
            margin-bottom: 4rem;

            @include lg {
                grid-template-columns: repeat(3, 1fr);
                .event-item {
                    min-height: 300px;
                }
            }

            @include md {
                grid-template-columns: repeat(2, 1fr);
                .event-item {
                    min-height: 200px;
                }
            }

            @include xs {
                grid-template-columns: repeat(1, 1fr);
                .event-item {
                    min-height: 100px;
                }
            }
            a {
                text-decoration: none;
            }
        }
    }
}

// Single news page

#single-news {
    margin: 2em auto;
    box-sizing: border-box;
    .show-text-wrapper {
        position: relative;
        padding: 2rem;

        .single-news-content::after {
            content: "";
            display: block;
            border-top: 1px solid rgb(229, 229, 229);
            margin: 2rem 0;
        }
    }
    .news-tag-list {
        list-style: none;
        display: flex;
        flex-flow: row wrap;
        gap: 1rem;
    }
    .news-tag {
        color: $primary;
        font-size: 0.8rem;
        // line in between tags doesnt show

        // &::after{
        //     content: '';
        //     background-color: $primary;
        //     width: 1px;
        //     height: .8rem;
        //     margin-left: .5rem;
        // }
    }
}

// Single events page
#single-event {
    margin: 2em auto;
    margin-top: 0;
    box-sizing: border-box;

    .show-text-wrapper {
        position: relative;
        padding: 2rem;
        @include md {
            padding: 5rem 2rem 2rem 2rem;
        }
    }

    a {
        color: $primary;
    }

    .single-event-details,
    .single-event-content {
        padding-bottom: 1rem;
    }

    .single-event-details {
        font-weight: bold;
    }

}
